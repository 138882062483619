<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'ctjs'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'ctjs'" />
    <!-- 行业分析 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">行业分析</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi_ctjs_bg.png">
        </div>
        <div>
          <div class="text">建设行业现状主要存在以下五方面问题：</div>
          <div class="text" style="margin-top:15px">监管难度大：建筑工程体量大、工地环境复杂、项目涉及环节多，如施工质量、安全、进度、材料、设备、环境、人员等，多种元素交叉造成工地全局监管难度大，各环节管控要求极高。人员管理难：工地项目人员数量多、流动性大，缺乏有效的组织、监管不到位易导致人员管理混乱、劳务纠纷多、现场违规操作多发，给企业用工管理带来很大难度，同时存在着极大的风险。安全事故频发：施工作业属于高风险，工地现场人、车、物安全隐患多，工地缺乏针对施工作业的智能监管手段，无法做到对安全事故进行防范预警，导致安全事故频发，造成巨大损失。信息孤岛严重：工地点多面广，协作方多，数据分散不共享、应用碎片化，容易形成信息孤岛，导致业务流程割裂，各方对接难，协同工作效率低，施工作业效率低，项目管理盲区多、漏洞多。信息化水平弱：传统粗放式监管信息化水平弱，工地数字化、智慧化需求迫切，急需跨域数据融合，全面感知人、车、物、环境等，实现业务智能联动，实现项目可视可管可控。</div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa_ctjs.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" :from="'ctjs'" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'ctjs'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      width: 594px;
      height: 410px;
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1200px;
    height: 664px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 600px;
    height: 500px;
    bottom: 4%;
    right: -22%;
  }
}
</style>

